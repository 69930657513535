body{
    overflow-x: hidden;
    overflow-y: hidden;
}
.welcomebody{
    margin: 0 2%;
}
.circle_box {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.box{
    background: unset !important;
    border-radius: 30px;
    width: 150px;
    height: 150px;
    position: absolute;
    top: calc(50% - 150px / 2);
    left: calc(50% - 150px / 2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dataContainer{
    .middleCircle{
        img{
            position: relative;
        }
    }
    @media only screen and (max-width: 800px){
        display: grid;
        .leftCircle, .middleCircle, .rightDetails{
            width: 100% !important;
        }
    }
    margin: 20vh 0;
    height: 60vh;
    .leftCircle, .middleCircle{
        display: flex;
        align-items: center;
        height: 100%;
        width: 30%;
        float: left;
        .cls-1 {
            fill: #776cc2;
            stroke: #776cc2;
        }
        
        .cls-2 {
            stroke: none;
        }
        
        .cls-3 {
            fill: none;
        }
        svg, a, img{
            margin: 0 auto;
            display: block;
        }
        a{
            color: black;
            text-decoration: none;
        }
    }
    .rightDetails{
        float: right;
        height: 100%;
        width: 36%;
        display: table;
        .center_verticle{
            display: table-cell; 
            vertical-align: middle;
            span{
                display: inline-block
            }
            .heyline{
                font-family: 'Roboto Mono',sans-serif;
                font-size: 11px;
                line-height: 34px;
            }
            .designation{
                font-weight: 900;
                font-size: 30px;
            }
            .short_desc{
                font-family: 'Roboto Slab',sans-serif;
                font-size: 14px;
                .nospan{
                    display: unset;
                }
            }
        }
    }
}