body {
  margin: 0 !important;
  padding: 0;
  font-family: Roboto;
  background-color: #f5f5f5;
  overflow-x: unset !important;
  overflow-y: unset !important;
  .switch-style{
    display: grid;
    justify-content: right;
    font-weight: 500;
    margin: 1em;
    .switch-toggle {
      display: block;
      float: left;
      border: 1px solid #dadada;
      border-radius: 1em;
    }
    .switch-toggle input {
      position: absolute;
      opacity: 0;
    }
    .switch-toggle input + label {
      padding: 7px;
      float: left;
      color: #8f8f8f;
      cursor: pointer;
      padding: 10px;
    }
    .switch-toggle input:checked + label {
      background: #776cc2;
      color: #fff;
      border-radius: 1em;
    }
  }
  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
}
