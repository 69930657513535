.homebody{
    max-width: 1220px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 14px;
    @media only screen and (min-width: 801px){
    padding-top: 5vh;
    }
    @media only screen and (max-width: 800px){
    padding-top: 10vh;
    }
    .biglbl{
        padding: 0;
        margin: 0;
        color: #e8e8e8;
        font-size: 8em;
        font-weight: 900;
        text-align: center;
        text-transform: uppercase;
        font-family: Roboto;
    }
    .bigsublbl, .skillslbl{
        position: relative;
        color: #8f8f8f;
        font-size: 3.5em;
        font-weight: 400;
        text-align: center;
        text-transform: none;
    }
    .skillslbl{
        margin-top: -25px;
    }
    .bigsublbl{
        margin-top: -75px;
    }
    .skillsgrid{
        display: grid;
        @media only screen and (min-width: 801px){
            grid-template-columns: repeat(6, 1fr);
        }
        @media only screen and (max-width: 800px){
            grid-template-columns: repeat(3, 1fr);
        }
        gap: 1em;
    }
    .skillogo{
        height: 4em;
        width: 4em;
        object-fit: scale-down;
    }
    .aboutGrid{
        display: grid;
        grid-template-columns: 70% 30%;
        color: #636363;
        .links{
            grid-gap: 1em;
            display: grid;
            grid-template-columns: repeat(5,auto);
            list-style-type: none;
            margin-bottom: 2em;
            a{
                text-decoration: none;
                // color: unset;
            }
            a::after{
                content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' id='Layer' enable-background='new 0 0 64 64' height='10' viewBox='0 0 64 64' width='10'><path d='m37.379 12.552c-.799-.761-2.066-.731-2.827.069-.762.8-.73 2.066.069 2.828l15.342 14.551h-39.963c-1.104 0-2 .896-2 2s.896 2 2 2h39.899l-15.278 14.552c-.8.762-.831 2.028-.069 2.828.393.412.92.62 1.448.62.496 0 .992-.183 1.379-.552l17.449-16.62c.756-.755 1.172-1.759 1.172-2.828s-.416-2.073-1.207-2.862z'/></svg>");
            }
        }
        .aboutdesc{
            margin-top: 16px;
            text-align: justify;
            font-weight: 700;
            margin-bottom: 2em;
            .subdesc{
               display: block;
               font-weight: 100;
               font-family: sans-serif;
               justify-content: space-evenly;
            }
            .width20{
                width: 40em;
            }
            .thankslbl{
                display: inline-block;
                padding: 2em;
            }
        }
    }
    @media only screen and (max-width: 800px){
        .biglbl{
            font-size: 6em;
        }
        .aboutGrid{
            grid-template-columns: 100%;
            justify-content: center;
            .links{
                grid-template-columns: repeat(2,auto);
            }
            .width20{
                width: 20em;
            }
        }
        .thankslbl{
            padding: 2em;
        }
        .skillslbl{
            margin-top: 16px;
        }
        .width20{
            width: 100% !important;
        }
    }
}