.footer{
  margin-top: 1em;
  .footerLayout {
    display: flex;
    justify-content: space-between;
    padding: 2vh 2vw;
    width: 90vw;
    background: #FBF8F3;
  }
  .bottomNav{
    font-size: 1rem;
    background: #776cc2;
    color: white;
    text-align: center;
    padding: 3vh;
    font-weight: 300;
  }
}