.workBody{
  max-width: 1220px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 14px;
  .paddingtopli{
    padding-top: 8px;
  }
  @media only screen and (min-width: 801px){
    padding-top: 15vh;
  }
  @media only screen and (max-width: 800px){
    padding-top: 10vh;
  }
  .experience{
      a{
        text-decoration: none;
      }
      p{
        ul{
          list-style: none;
        }
      }
      .tcli{
        display: flex;
        justify-content: center;
      }
      .tcimg{
        object-fit: contain;
        height: 3em;
        width: 3em;
        margin-right: 1em;
      }
      .rowexp{
        border-bottom: 1px solid #bbbbbb;
        .notes{
          font-size: 12px;
          img{
            object-fit: contain;
          }
          a{
            color: #8f8f8f;
            text-decoration: unset;
          }
        }
      }
      img{
          height: 2em;
          width: 6em;
      }
      span, .notes{
        color: #8f8f8f;
      }
  }
  .worklbl{
    padding: 0;
    margin: 0;
    color: #e8e8e8;
    font-size: 8em;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    font-family: Roboto;
    margin-top: 5rem;
    
  }
  .worksublbl{
    position: relative;
    margin-top: -75px;
    color: #8f8f8f;
    font-size: 3.5em;
    font-weight: 400;
    text-align: center;
    text-transform: none;
  }
  .workgreed{
    // border: 1px solid #dadada;
    display: grid;
    row-gap: 1em;
    column-gap: 1em;
    @media only screen and (min-width: 801px){
      grid-template-columns: auto auto auto;
    }
    @media only screen and (max-width: 800px){
      grid-template-columns: auto;
    }
    .card{
      box-shadow: 1px 1px 3px 0 #dadada;
      background-color: #fff;
      padding: 1em;
      display: grid;
      grid-template-rows: 40% 10% 45% 10%;
      img{
        display: inline-block;
        max-width: 100%;
        justify-self: center;
      }
      .title{
        font-weight: 500;
        font-size: 2em;
        margin: 0;
        color: #676767;
      }
      .description{
        font-size: 1.2em;
        color: #636363;
        line-height: 1.6em;
      }
      .link{
        display: flex;
        color: #636363;
        font-size: 1.2em;
        justify-content: space-between;
      }
      .logoc{
        height: 1.5em;
        width: 1.5em;
        padding-left: 0.5em;
      }
      .techstack{
        
      }
    }
    .card:hover{
      box-shadow: 3px 3px 15px 0 #e0e0e0;
      -webkit-transform: translate(0px, -2px);
      -ms-transform: translate(0px, -2px);
      transform: translate(0px, -2px);
      color: #555;
    }
  }
}