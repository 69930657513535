.header {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  height: 10vh;
  position: fixed;
  z-index: 9999;
  width: 92vw;
  padding: 0% 4vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: 1px;
  .innerlayout {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
    .companyLogoLayout {
      a{
        text-decoration: none;
        color: #776cc2;
        font-weight: 900;
        letter-spacing: 2px;
        font-size: 1.5em;
      }
      cursor: pointer;
      width: 11%;
      img {
        height: 100%;
        width: 91%;
      }
    }
    ul {
      font-weight: 500;
      li{
        a{
          text-decoration: none;
          color: black;
          font-weight: 500;
        }
      }
    }

    .webLayout {
      font-size: 13px;
      text-transform: uppercase;
      display: inline-block;
      font-weight: 700;
      @media only screen and (min-width: 470px){
        margin: 0rem 1rem;
      }
      padding: 0 0.9rem 0.2rem 0.9rem;
      cursor: pointer;
    }
  }
}